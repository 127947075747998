import "./Kingdoms.css";
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
const Map = React.lazy(() => import('./map').then(({ Map }) => ({ default: Map })));

function KingdomsApp()
{
    return (      <Suspense fallback={<div>Загрузка...</div>}>
              <Router>
                <Switch>
                  <Route path='/' exact component={Map} />
                  <Route path='/PublicMap' component={Map} />
                  </Switch>
            </Router>
      </Suspense>);
}

export default KingdomsApp;